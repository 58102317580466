import "./App.css";
import { Calculator } from "./Component/Calculator/index";
// import { Hero } from "./Hero";
// import { Footer } from "./Component/Footer";

function App() {
  return (
    <Calculator />
    // {/* <Hero /> */}
    // {/* <Footer /> */}
  );
}

export default App;
